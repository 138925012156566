<template>
  <div></div>
</template>

<script>
import store from '@/store/index.js'
export default {
  components: {},

  metaInfo: {
    title: 'AutoLogin'
  },

  data () {
    return {
      appName: process.env.VUE_APP_BRAND_NAME
    }
  },

  mounted () {
    if (this.$route.query.email && this.$route.query.token) {
      store.commit('authentication/setAuthenticated', true)
      store.commit('authentication/setToken', this.$route.query.token)
      window.location.href = '/'
    }
  },

  methods: {},

  computed: {}
}
</script>
